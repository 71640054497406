import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: rgba(23, 24, 32, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  border: 1px solid #302CF5;
  border-radius: 55px;
  padding: 55px;
  width: 560px;
  color: #fff;
  box-sizing: border-box;
  background: rgba(23, 24, 32, 0.75);
`;

export const Header = styled.div`
  margin-bottom: 20px;  
  font-size: 21px;
  font-weight: 500;
`;

export const Button = styled.button`
  background: #302CF5;
  border: none;
  height: 44px;
  width: 140px;
  position: relative;
  cursor: pointer;  
  border-radius: 8px;  
  color: #fff;
  font-weight: 500;
  margin: 10px 0 0 307px;
  &:hover {
    background: #262730;
  }
`;
