import styled from "styled-components";

export const WrapperMessage = styled.div`
  display: flex;
  align-items: end;
  margin-bottom: 8px;
`;

const title = `
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 100%;
  color: #fff;
  text-align: center;
  margin-right: 12px;
  padding: 7px 0 0 0;
  box-sizing: border-box;
`

export const TitleUser = styled.div`
  background: #262730;
  ${title}
`;

export const TitleAi = styled.div`
  background: #302CF5;
  ${title}
`;

export const WrapperText = styled.div`
  max-width: calc(100% - 112px);  
`;

export const DateContainer = styled.div`
  color: #5D6070;
  font-size: 12px;
  line-height: 28px;
`;

const text = `
  border-radius: 16px 16px 16px 0;
  padding: 10px 12px;
  box-sizing: border-box;
  white-space: pre-line;
`

export const TextAi = styled.div<{ error: boolean }>`
  background: #78A6FF;
  color: #171820;
  ${text}
  ${({error}) => error ? `
        background: #C669FF;
    ` : ``}
`;

export const TextUser = styled.div`
  background: #262730;
  color: #fff;
  ${text}
`;
