import styled from "styled-components";

export const ContainerWrapper = styled.div`
  height: 1px;
  flex-grow: 1;
`;

export const Wrapper = styled.div`
  max-width: 1300px;
  width: 100%;
  height: calc(100% - 20px);
  margin: 0 auto;
  padding: 0 10px;  
  box-sizing: border-box;
`;

export const ListMessages = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const ThreeDotsWrapper = styled.div`
  margin-left: 52px;
`;