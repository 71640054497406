import {format} from 'date-fns'

import {WrapperMessage, TitleAi, TitleUser,  WrapperText, DateContainer, TextAi, TextUser} from "./styles";
import {MessageProps} from './types'

export function Message({
                            data: {text, time, isResponse, isError}
                        }: MessageProps) {

    const formatted = format(new Date(time), 'h:mm aaa')
    return (
        <WrapperMessage >
            {isResponse && <TitleAi>AI</TitleAi>}
            {!isResponse && <TitleUser>You</TitleUser>}
            <WrapperText>
                <DateContainer>{formatted}</DateContainer>
                {isResponse && <TextAi error={isError}>{text}</TextAi>}
                {!isResponse && <TextUser>{text}</TextUser>}
            </WrapperText>
        </WrapperMessage>
    )
}
