import React from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider, QueryCache} from 'react-query'

import {HomePage} from '@pages/home';
import {Header} from '@components/header';
import {Footer} from '@components/footer';

import {MainContainer} from './styles';
import './index.css'

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } }
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <QueryClientProvider client={queryClient}>
        <React.StrictMode>
            <MainContainer>
                <Header/>
                <HomePage/>
                <Footer/>
            </MainContainer>
        </React.StrictMode>
    </QueryClientProvider>
);
