import {useState, useRef, useContext, useEffect, KeyboardEvent} from "react"
import Recaptcha from "react-google-recaptcha"

import {useChatResponse} from "@services/useChatResponse"
import {ChatContext} from '@pages/home/context'

import {ContainerWrapper, Wrapper, Button, TelegramPlane, Input} from "./styles";

export function InputContainer() {
    const [question, setQuestion] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const captchaRef = useRef<Recaptcha>(null);
    const {messages, setMessages, setIsLoading, selectedTemplate, setSelectedTemplate} = useContext(ChatContext);
    const {isLoading, isRefetching} = useChatResponse({
        question, handleOnSuccess: ({message, error}) => {
            setMessages([
                ...messages,
                {
                    text: message || error,
                    isError: !!error,
                    isResponse: true,
                    time: new Date().getTime()
                }
            ]);
        }
    });

    useEffect(() => {
        setIsLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        setIsLoading(isRefetching);
    }, [isRefetching]);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = selectedTemplate;
        }
    }, [selectedTemplate]);

    const onButtonClick = () => {
        if (inputRef.current && !isLoading && !isRefetching && inputRef.current.value.length > 4) {
            const message = inputRef.current.value || '';
            if (message) {
                executeCaptcha()
            }
        }
    }


    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode === 13 && !isLoading && !isRefetching) {
            onButtonClick();
        }
    }

    const executeCaptcha = () => {
        captchaRef.current?.execute()
    };
    const verifyCallback = (response: any) => {
        if (inputRef.current) {
            const message = inputRef.current.value || '';
            setQuestion(`${message}###t=${new Date().getTime()}`);
            setMessages([
                ...messages,
                {
                    text: message,
                    isResponse: false,
                    isError: false,
                    time: new Date().getTime()
                }
            ]);
            setSelectedTemplate('');
            inputRef.current.value = '';
        }
    };

    return (
        <ContainerWrapper>
            <Wrapper>
                <Input ref={inputRef} type="text" onKeyDown={onKeyDown} minLength={5} maxLength={255} placeholder={'Type here'}/>
                <Button onClick={onButtonClick} type='button'>
                    <TelegramPlane/>
                </Button>
            </Wrapper>
            <Recaptcha ref={captchaRef}
                       size="invisible"
                       sitekey="6LcK5eMoAAAAAPgXf0ZSey3EssBPZCxmpwpctUEk"
                       onChange={verifyCallback}
            />
        </ContainerWrapper>
    )
}
