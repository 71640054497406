import {useContext} from "react";

import {ChatContext} from "@pages/home/context";

import {Container, Wrapper, Header, Button} from "./styles";

export function Disclaimer() {
    const {setShowDisclaimer} = useContext(ChatContext);

    const onButtonClick = () => {
        setShowDisclaimer(false)
        sessionStorage.disclaimerClosed = true
    }

    return (
        <Container>
            <Wrapper>
                <Header>Disclaimer of Liability</Header>
                <div>
                    The underlying OFAC SDN list data is maintained and controlled by the U.S. government.
                    For comprehensive and up-to-date data, we strongly recommend visiting the official website.
                </div>
                <div>
                    AI Watchlist Chat is for informational purposes only and should not be relied upon as an absolute source of truth.
                    Sprinterra is not responsible for the reliability of the data and/or the responses generated by the AI Chat based on this data.
                </div>
                <Button onClick={onButtonClick} type='button'>I agree</Button>
            </Wrapper>
        </Container>
    )
}
