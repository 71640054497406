import {useState} from 'react'

import {QueryTemplate} from '@components/queryTemplate';
import {InputContainer} from '@components/inputContainer';
import {ContentText} from '@components/contentText';
import {Disclaimer} from '@components/disclaimer';

import {ChatContext} from './context'
import {Messages} from './types';

export function HomePage() {
    const [messages, setMessages] = useState<Messages>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState<string>('');
    const [showDisclaimer, setShowDisclaimer] = useState(!sessionStorage.disclaimerClosed);

    return (
        <ChatContext.Provider value={{messages, setMessages, isLoading, setIsLoading, selectedTemplate, setSelectedTemplate, showDisclaimer, setShowDisclaimer}}>
            <ContentText/>
            <InputContainer/>
            <QueryTemplate/>
            {showDisclaimer && <Disclaimer/>}
        </ChatContext.Provider>
    )
}
