import {useContext, useRef, useEffect} from "react";
import {ThreeDots} from "react-loader-spinner";

import {ChatContext} from "@pages/home/context";
import {Message} from "@components/message";

import {ContainerWrapper, Wrapper, ListMessages, ThreeDotsWrapper} from "./styles";

export function ContentText() {
    const {messages, isLoading} = useContext(ChatContext);
    const scrollToRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        scrollToRef.current?.scrollIntoView({behavior: 'smooth'})
    }, [isLoading])

    return (
        <ContainerWrapper>
            <Wrapper>
                <ListMessages>
                    {messages.map((message, index) => (<Message data={message} key={`message_${index}`}/>))}
                    {isLoading && <ThreeDotsWrapper>
                        <ThreeDots
                            height="80"
                            width="80"
                            radius="9"
                            color="#302CF5"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            visible={true}
                        />
                    </ThreeDotsWrapper>}
                    <div ref={scrollToRef}/>
                </ListMessages>
            </Wrapper>
        </ContainerWrapper>
    )
}