import {useContext, useState} from 'react';

import {ChatContext} from "@pages/home/context";

import {ContainerWrapper, Wrapper, ListTemplates, TemplateItem, Button, WrapperDisclaimer, } from "./styles";

export function QueryTemplate() {
    const [showTemplates, setShowTemplates] = useState(false);
    const {setSelectedTemplate} = useContext(ChatContext);
    const templates = [
        'Is [Name] included in the sanctions lists?',
        'Provide more information about the sanctioned [Name]',
        'Is [Name] currently under sanctions imposed by the OFAC?',
        'Provide more information about the sanctioned [Company Name]',
        'What country has the highest number of individuals under sanctions?',
        'How many individuals are currently under sanctions in [Country]?'
    ]

    const onShowToggle = () => {
        setShowTemplates(!showTemplates)
    }

    const onTemplateClick = (template: string) => {
        setSelectedTemplate(template);
    }

    return (
        <ContainerWrapper showtemplates={showTemplates ? 1 : 0}>
            <Wrapper>
                <ListTemplates showtemplates={showTemplates ? 1 : 0}>
                    {templates.map((template, index) => (<TemplateItem onClick={() => {onTemplateClick(template)}} key={index}>{template}</TemplateItem>))}
                </ListTemplates>
                <Button showtemplates={showTemplates ? 1 : 0} onClick={onShowToggle}>
                    {showTemplates ? 'Show' : 'Hide'} <span>^</span>
                </Button>
            </Wrapper>
            <WrapperDisclaimer showtemplates={showTemplates ? 1 : 0}>
               <div>Disclaimer of Liability</div>
                The underlying OFAC SDN list data is maintained and controlled by the U.S. government. For comprehensive and up-to-date data, we strongly recommend visiting the official website.
                <div>
                    AI Watchlist Chat is a test project for informational purposes only and should not be relied upon as an absolute source of truth.
                    Sprinterra is not responsible for the reliability of the data and/or the responses generated by the AI Chat based on this data.
                </div>
                <div>Please contact us at openai@sprinterra.com if you have any question or require any additional information.</div>
            </WrapperDisclaimer>
        </ContainerWrapper>
    )
}
