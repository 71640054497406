import {HeaderWrapper, LogoSprinterra, Wrapper, LogoAi, By, LinkSprinterra} from "./styles";

export function Header() {
    return (
        <HeaderWrapper>
            <Wrapper>
                <LogoAi src="/images/logo-ai.png" alt=""/>
                <By src="/images/by.png" alt=""/>
                <LinkSprinterra href={'https://www.sprinterra.com/'} target='_blank'>
                    <LogoSprinterra src="/images/logo-sprinterra.png" alt=""/>
                </LinkSprinterra>
            </Wrapper>
        </HeaderWrapper>
    )
}
