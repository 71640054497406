import styled from "styled-components";

export const ContainerWrapper = styled.div<{
    showtemplates: number
}>`
  min-height: 50px;
  overflow: hidden; 
  ${({showtemplates}) => showtemplates === 1 ? `
        height: 50px;
    ` : `
        height: 100%;
    `}
`;

export const Wrapper = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
`;

export const ListTemplates = styled.div<{
    showtemplates: number
}>`
  width: calc(100% - 60px);
  box-sizing: border-box;
  column-gap: 20px;
  column-count: 2;
  padding: 12px 0 0 0;
  overflow: hidden;
  ${({showtemplates}) => showtemplates === 1 && `
        height: 0;
  `}
`;

export const TemplateItem = styled.div`
  background: #302CF5;
  border-radius: 8px;
  padding: 8px 16px;
  color: #fff;
  margin: 0 0 12px 0;
  cursor: pointer;
`;

export const Button = styled.button<{
    showtemplates: number
}>`
  background: transparent;
  border: none;
  padding: 0 10px 0 0;
  margin: 16px 0 0 0;
  color: #302CF5;
  font-size: 16px;
  height: 16px;
  width: 56px;
  position: relative;
  text-align: right;
  cursor: pointer;

  > span {
    transition: transform .2s linear;
    display: inline-block;
    height: 16px;
    position: absolute;
    top: 3px;
    right: -5px;
    ${({showtemplates}) => showtemplates === 1 && `
        transform: rotate(180deg);
    `}
  }
`;

export const WrapperDisclaimer = styled.div<{
    showtemplates: number
}>`
  max-width: 1300px;
  width: 100%;
  margin: 4px auto 16px;
  padding: 0 10px;
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  color: #545665;
  text-align: justify;
  overflow: hidden;
  ${({showtemplates}) => showtemplates === 1 && `
        height: 0;
  `}
`;
