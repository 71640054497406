import styled from "styled-components";
import {FaTelegramPlane} from "react-icons/fa";

export const ContainerWrapper = styled.div`
  height: 72px;
  background: #171820; 
  border-bottom: 1px solid #262730;
  border-top: 1px solid #262730;
`;

export const Wrapper = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 12px 10px;  
  box-sizing: border-box;
  display: flex;
`;

export const Button = styled.button`
  background: #302CF5;
  border: none;
  padding: 12px 12px 10px 10px; 
  height: 48px;
  width: 48px;
  position: relative;
  cursor: pointer;  
  border-radius: 100%;  
  &:hover {
    background: #262730;
  }
`;
export const TelegramPlane = styled(FaTelegramPlane)`
  color: #fff;
  font-size: 25px;
`;

export const Input = styled.input`  
  width: 100%;
  margin: 0 14px 0 0;
  padding: 0 16px;  
  box-sizing: border-box;
  background: #262730;
  border: 1px solid #5D6070;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
  color: #fff;
  &::placeholder {
    color: #5D6070;
  }
  &:focus {
    outline: none;
  }
`;
