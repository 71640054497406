import {createContext} from 'react';

import {Messages} from '@pages/home'

interface ChatContextType {
    messages: Messages
    setMessages: (
        data: Messages
    ) => void | React.Dispatch<React.SetStateAction<Messages>>
    isLoading: boolean
    setIsLoading: (
        data: boolean
    ) => void | React.Dispatch<React.SetStateAction<boolean>>
    selectedTemplate: string
    setSelectedTemplate: (
        data: string
    ) => void | React.Dispatch<React.SetStateAction<string>>
    showDisclaimer: boolean
    setShowDisclaimer: (
        data: boolean
    ) => void | React.Dispatch<React.SetStateAction<boolean>>
}

export const ChatContext = createContext<ChatContextType>({
    messages: [],
    setMessages: function () {

    },
    isLoading: false,
    setIsLoading: function () {

    },
    selectedTemplate: '',
    setSelectedTemplate: function () {

    },
    showDisclaimer: false,
    setShowDisclaimer: function () {

    },
})
