import styled from "styled-components";

export const FooterWrapper = styled.div`
  height: 54px;
  border-top: 1px solid #262730;
  background: #171820;
`;

export const Wrapper = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  color: #545665;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 16px 10px 0;
  box-sizing: border-box;
`;

export const LinkSprinterra = styled.a`
  display: inline-block;
  color: #302CF5;
  text-decoration: none;  
  &:hover {
    text-decoration: underline;
  }
`;