import {useQuery} from "react-query"
import axios from "axios"

export function useChatResponse({question, handleOnSuccess}: {
    question: string
    handleOnSuccess: (response: {
        message: string
        error: string
    }) => void
}) {
    return useQuery(
        ['chatResponse', question],
        async () => {
            const message = question.split('###t=')[0]
            return await axios
                .get('https://ai-watchlist.sprinterra.xyz/api/question/' + encodeURIComponent(message))
                // .get('/api/question/' + encodeURIComponent(message))
                .then(response => response.data);
        },
        {
            keepPreviousData: true,
            enabled: !!question,
            onSuccess: (response) => {
                handleOnSuccess(response);
            }
        }
    )
}

