import styled from "styled-components";

export const HeaderWrapper = styled.div`
  height: 80px;
  background: #171820; 
  border-bottom: 1px solid #262730;
`;

export const Wrapper = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  align-items: end;
  height: 70px;
  box-sizing: border-box;
`;

export const LogoSprinterra = styled.img`
`;

export const LogoAi = styled.img`
  display: inline-block;
  margin-bottom: 16px;
`;

export const By = styled.img`
  display: inline-block;
  margin: 0 25px 13px;
`;

export const LinkSprinterra = styled.a`
  display: inline-block;
`;
