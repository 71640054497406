import {FooterWrapper, Wrapper, LinkSprinterra} from "./styles";

export function Footer() {
    return (
        <FooterWrapper>
            <Wrapper>
                <div>© 2023 Sprinterra</div>
                <div>Developed by  <LinkSprinterra href={'https://www.sprinterra.com/'} target='_blank'>Sprinterra</LinkSprinterra></div>
            </Wrapper>
        </FooterWrapper>
    )
}